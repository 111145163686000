import { FormInfoDataDto, toNumberOrDefault } from "table";
import { FormInfoDataPointer } from "table/dist/services/Api/types/FormReadDto";

export function formInfoDataPointerSortedComparer(
  x1: FormInfoDataPointer | undefined,
  x2: FormInfoDataPointer | undefined,
  getColIndex?: (col_name: string) => number | undefined,
): number {
  if (!x1) {
    return -1;
  }
  if (!x2) {
    return 1;
  }

  if (typeof x1 === "string" || typeof x2 === "string") {
    if (typeof x2 !== "string") {
      return -1;
    }

    if (typeof x1 !== "string") {
      return 1;
    }

    return x1.localeCompare(x2);
  }

  const result = toNumberOrDefault(x1.row, -1) - toNumberOrDefault(x2.row, -1);
  if (result !== 0) {
    return result;
  }

  if (!("col_name" in x2)) {
    return 1;
  }

  if (!("col_name" in x1)) {
    return -1;
  }

  if (typeof getColIndex !== "function") {
    return 0;
  }

  return (
    toNumberOrDefault(getColIndex(x1.col_name), -1) -
    toNumberOrDefault(getColIndex(x2.col_name), -1)
  );
}

export function formInfoDataSortedComparer(
  x1: FormInfoDataDto | undefined,
  x2: FormInfoDataDto | undefined,
  getColIndex?: (col_name: string) => number | undefined,
): number {
  return formInfoDataPointerSortedComparer(
    x1?.pointer,
    x2?.pointer,
    getColIndex,
  );
}

export function getFormInfoDataSortedComparer(
  getColIndex?: (col_name: string) => number | undefined,
) {
  return (x1: FormInfoDataDto | undefined, x2: FormInfoDataDto | undefined) => {
    return formInfoDataSortedComparer(x1, x2, getColIndex);
  };
}
