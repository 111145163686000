import { createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.tableDataSet)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        style: _normalizeStyle(_ctx.divStyle),
        class: "header-save-indicator"
      }, [
        (_ctx.tableDataSet.repository._lastError)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(" Ошибка. Повторная попытка сохранения.. ")
            ], 64))
          : (_ctx.tableDataSet.repository.queue.length)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.tableDataSet.repository.queue.length) + " сейчас сохраняется ", 1)
              ], 64))
            : _createCommentVNode("", true)
      ], 4))
    : _createCommentVNode("", true)
}