import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, mergeProps as _mergeProps, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02b44ca9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toolbar padding-bottom-10" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "toolbar__extended-buttons" }
const _hoisted_4 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableActionsComponent = _resolveComponent("TableActionsComponent")!
  const _component_ActionsButtons = _resolveComponent("ActionsButtons")!
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!
  const _component_DropdownComponent = _resolveComponent("DropdownComponent")!
  const _component_PanelFilters = _resolveComponent("PanelFilters")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.table)
        ? (_openBlock(), _createBlock(_component_TableActionsComponent, {
            key: 0,
            "table-data-set": _ctx.table.tableDataSet,
            "filter-state": "enable",
            "update-indexes-state": "auto"
          }, null, 8, ["table-data-set"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2)),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.table && _ctx.formActions?.toolbar?.length)
          ? (_openBlock(), _createBlock(_component_ActionsButtons, {
              key: 0,
              "data-array": _ctx.formActions.toolbar,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClickFormAction($event.data)))
            }, null, 8, ["data-array"]))
          : _createCommentVNode("", true),
        (_ctx.table && _ctx.repositoryExtensionsFilterComputed.length)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.repositoryExtensionsFilterComputed, (repExt) => {
              return (_openBlock(), _createBlock(_component_ButtonComponent, {
                key: repExt.method + repExt.caption,
                class: "form-button",
                style: {"white-space":"nowrap"},
                onClick: ($event: any) => (_ctx.openRepositoryExtension(repExt))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(repExt.caption), 1)
                ]),
                _: 2
              }, 1032, ["onClick"]))
            }), 128))
          : _createCommentVNode("", true),
        (_ctx.table && _ctx.fillBySnk)
          ? (_openBlock(), _createBlock(_component_ButtonComponent, {
              key: 2,
              disabled: !_ctx.table.tableDataSet.selected.cursor,
              class: "form-button",
              style: {"white-space":"nowrap"},
              onClick: _ctx.openFillByAnalyticalReport
            }, {
              default: _withCtx(() => [
                _createTextVNode(" СНК ")
              ]),
              _: 1
            }, 8, ["disabled", "onClick"]))
          : _createCommentVNode("", true),
        (_ctx.optionsExport.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_DropdownComponent, _mergeProps(_ctx.DEFAULT_DROPDOWN_PROPS, {
                options: _ctx.optionsExport,
                classButton: "form-button"
              }), {
                default: _withCtx(() => [
                  _createTextVNode(" Экспорт ")
                ]),
                _: 1
              }, 16, ["options"])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.table?.tableDataSet.options.filtersSet.open)
      ? (_openBlock(), _createBlock(_component_PanelFilters, {
          key: 0,
          "data-set": _ctx.table.tableDataSet.options.filtersSet,
          "model-array": _ctx.table.tableDataSet.modelUnref,
          "table-data-set": _ctx.table.tableDataSet,
          types: _ctx.table.tableDataSet.types,
          class: "padding-bottom-10"
        }, null, 8, ["data-set", "model-array", "table-data-set", "types"]))
      : _createCommentVNode("", true)
  ], 64))
}