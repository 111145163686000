
import { defineComponent } from "vue";
import {
  ApiHelper,
  ApiService,
  Dictionary,
  FormInfoDataDto,
  FormReadDto,
  GlobalContext,
  HelpHelper,
  isNullOrUndefined,
  Logger,
  PanelFiltersSet,
  RepositoryReadDto,
  ScreenSpinner,
  TableCell,
  TableDataSet,
  TableDataSetFactory,
  TableDefaultRepository,
  toStr,
} from "table";
import { TableAppData } from "@/common/types/TableAppData";
import ReportingForm from "@/modules/editor-forms/components/ReportingForm/ReportingForm.vue";
import { APP_ICONS, HEADER_IDS } from "@/common/consts";
import { RouteLocationNormalizedLoaded } from "vue-router";
import { ReportDto } from "@/modules/registration-reports/services/ApiServiceRegReports/types";
import ApiServiceRegReports from "@/modules/registration-reports/services/ApiServiceRegReports/ApiServiceRegReports";
import { RepositoryExtensionDto } from "@/services/AppApiRepositoryService/types/RepositoryExtensionDto";
import AppApiRepositoryService from "@/services/AppApiRepositoryService/AppApiRepositoryService";
import AppApiFormsService from "@/services/AppApiFormsService/AppApiFormsService";
import { ReportFormActionsDict } from "@/services/ApiActions/types";
import { ReloadFormReadParams } from "@/modules/editor-forms/common/types";
import { AttrsDataSet } from "@/modules/editor-forms/components/ReportFormAttrs/classes/AttrsDataSet";
import { AttrsDefaultRepository } from "@/modules/editor-forms/components/ReportFormAttrs/classes/AttrsDefaultRepository";
import SwitchHints from "@/components/smart/SwitchHints.vue";
import { TableRowObj } from "table/dist/types/TableRowObj";
import { HelpHelperTableContext } from "table/dist/classes/HelpHelper";
import { FORM_INFO_KEYS } from "@/modules/editor-forms/common/consts";
import { PanelFiltersSaveModule } from "@/classes/PanelFiltersSaveModule";
import { applyPanelFiltersData } from "@/common/helpers/applyPanelFiltersData";
import SwitchEdit from "@/components/smart/SwitchEdit.vue";
import { PageGlobalContext } from "table/dist/services/GlobalContext/types";
import { TableDataSetOptions } from "table/dist/classes/Table/TableDataSet/TableDataSet";

interface AppData {
  isMounted: boolean;
  tables: Dictionary<TableAppData>;
  formRead: FormReadDto | null;
  formRepositoryRead: RepositoryReadDto | null;
  attrsDataSet: AttrsDataSet | null;
  loading: boolean;
  helpHelper: HelpHelper;
  errorMessage: any | null;
  errorMessageLabel: string;
  report: ReportDto | null;
  repositoryExtensions: RepositoryExtensionDto[] | null;
  formActions: ReportFormActionsDict | null;
  edit: boolean;
  isDeactivated?: boolean;
}

export default defineComponent({
  name: "EditorFormsDemo",
  components: {
    SwitchEdit,
    SwitchHints,
    ReportingForm,
    ScreenSpinner,
  },
  setup() {
    return {
      HEADER_IDS,
      APP_ICONS,
    };
  },
  data(): AppData {
    return {
      isMounted: false,
      tables: {},
      formRead: null as FormReadDto | null,
      formRepositoryRead: null,
      attrsDataSet: null,
      loading: true,
      helpHelper: new HelpHelper<HelpHelperTableContext>(),
      errorMessage: null,
      errorMessageLabel: "",
      report: null,
      repositoryExtensions: null,
      formActions: null as null | ReportFormActionsDict,
      edit: true,
    };
  },
  created() {
    this.$watch(
      () => this.errorMessage,
      async () => {
        this.errorMessageLabel = await this.getErrorLabel();
      },
    );

    const getFormRead = () => this.formRead;
    const getIsDeactivated = () => this.isDeactivated;
    GlobalContext.setAssign({
      get page_data() {
        if (getIsDeactivated()) {
          GlobalContext.setAssign({ page_data: undefined });
          return undefined;
        }

        return getFormRead();
      },
    } as Partial<PageGlobalContext>);
  },
  async mounted() {
    this.isMounted = true;
    await this.init();
    this.$watch(
      () => this.$route,
      (
        to: RouteLocationNormalizedLoaded,
        from: RouteLocationNormalizedLoaded,
      ) => {
        if (to.params.id !== from.params.id) {
          this.init();
        }
      },
    );
    this.$watch(
      () => [this.edit, Object.values(this.tables)],
      () => {
        this.setEditModePriority();
      },
      { immediate: true },
    );
  },
  deactivated() {
    this.isDeactivated = true;
  },
  methods: {
    async getErrorLabel() {
      if (!this.errorMessage) {
        return "";
      }
      if (typeof this.errorMessage === "object") {
        if ("json" in this.errorMessage) {
          const error = await ApiHelper.getError(this.errorMessage);
          if (error.type === "record_not_found_exception") {
            return "Запись удалена или ещё не создана.";
          }
        }
      }

      return toStr(this.errorMessage);
    },

    setEditModePriority() {
      const editModePriority: TableDataSetOptions["editModePriority"] = this
        .edit
        ? undefined
        : {};
      Object.values(this.tables).forEach((table) => {
        table.tableDataSet.setEditModePriority(editModePriority);
      });
    },

    async reloadFormRead(params: ReloadFormReadParams) {
      const formRead = await this.getFormRead();
      this.formRead = formRead;
      this.formActions = await this.getFormActions(formRead);
      this.updateReportingFormRules();
      if (!this.formRepositoryRead) {
        throw new Error(`formRepositoryRead is null`);
      }

      if (params.refresh === "all" || params.refreshInfo) {
        this.reloadInfoData(
          formRead,
          this.formRepositoryRead,
          params.tableName,
        );
      }

      if (params.refresh) {
        if (params.refresh === "all") {
          this.reloadAllRecords(
            formRead,
            this.formRepositoryRead,
            params.tableName,
          );
        }

        if (params.refresh === "record") {
          this.reloadAllRecords(
            formRead,
            this.formRepositoryRead,
            params.tableName,
          );
        }
      }
    },

    reloadAllRecords(
      formRead: FormReadDto,
      formRepositoryRead: RepositoryReadDto,
      _tableName?: string,
    ) {
      if (_tableName) {
        this.reloadAllRecordsByTableName(
          formRead,
          formRepositoryRead,
          _tableName,
        );
      }

      for (const tableName of Object.keys(formRepositoryRead.form.tables)) {
        this.reloadAllRecordsByTableName(
          formRead,
          formRepositoryRead,
          tableName,
        );
      }
    },

    reloadInfoData(
      formRead: FormReadDto,
      formRepositoryRead: RepositoryReadDto,
      _tableName?: string,
    ) {
      if (_tableName) {
        this.reloadInfoDataByTableName(
          formRead,
          formRepositoryRead,
          _tableName,
        );
      }

      for (const tableName of Object.keys(formRepositoryRead.form.tables)) {
        this.reloadInfoDataByTableName(formRead, formRepositoryRead, tableName);
      }
    },

    reloadInfoDataByTableName(
      formRead: FormReadDto,
      formRepositoryRead: RepositoryReadDto,
      tableName: string,
    ) {
      const tableAppData: TableAppData | undefined = this.tables[tableName] as
        | TableAppData
        | undefined;
      if (!tableAppData) {
        throw new Error(`not found table "${tableName}".`);
      }

      const { tableDataSet } = tableAppData;
      const tableInfo = this.getTableInfoData(formRead, tableName);
      tableDataSet.clearInfoData();
      tableDataSet.setInfoData(tableInfo);
    },

    reloadRecord(
      formRead: FormReadDto,
      formRepositoryRead: RepositoryReadDto,
      row: number,
      tableName: string,
    ) {
      const rowData = formRead.form_table_data?.[tableName]?.[row];
      if (!rowData) {
        throw new Error(
          `row data not found! tableName: "${tableName}", row: ${row}.`,
        );
      }

      const tableAppData: TableAppData | undefined = this.tables[tableName] as
        | TableAppData
        | undefined;
      if (!tableAppData) {
        throw new Error(`not found table: "${tableName}".`);
      }

      const { tableDataSet } = tableAppData;
      const tableRow = tableDataSet.getRowOrException(row);
      Object.keys(tableRow.cells).forEach((col_name) => {
        TableCell.setValue(tableRow.cells[col_name], rowData[col_name]);
      });
    },

    reloadAllRecordsByTableName(
      formRead: FormReadDto,
      formRepositoryRead: RepositoryReadDto,
      tableName: string,
    ) {
      const tableAppData: TableAppData | undefined = this.tables[tableName] as
        | TableAppData
        | undefined;
      if (!tableAppData) {
        throw new Error(`not found table "${tableName}".`);
      }

      const { tableDataSet } = tableAppData;
      const tableDto = formRepositoryRead.form.tables[tableName];
      const {
        headers,
        model: modelDtoArray,
        enable_col_num,
        options: optionsDto,
      } = tableDto;
      this.setEditModeByFormRead(formRead, tableDataSet);
      const uniqId = toStr("report-form-id-" + formRead.report_form_id);
      const factory = new TableDataSetFactory({
        tableName,
        uniqId,
        headers,
        modelDtoArray,
        rows: formRead.form_table_data?.[tableName] ?? [],
        repository: new TableDefaultRepository(),
        types: formRepositoryRead.types,
        edit: tableDataSet.editMode,
        tableDataSetOptions: {
          optionsDto,
        },
      });
      const tableRows: TableRowObj[] = factory.getTableRows(
        tableAppData.tableDataSet.modelUnref,
      );
      tableDataSet.setTableRows(tableRows);
    },

    updateReportingFormRules() {
      if (!this.formRead) {
        return;
      }

      if (this.attrsDataSet) {
        this.attrsDataSet.setEditByFormRead(this.formRead);
      }

      for (const tableKey of Object.keys(this.tables)) {
        const table = this.tables[tableKey];
        if (table.tableDataSet) {
          this.setEditModeByFormRead(
            this.formRead,
            table.tableDataSet as TableDataSet,
          );
        }
      }
    },

    setEditModeByFormRead(formRead: FormReadDto, tableDataSet: TableDataSet) {
      tableDataSet.setEditMode({
        edit: formRead.edit.edit,
        comment: formRead.edit.comment,
        remark: formRead.edit.remark,
        warning: formRead.edit.warning,
        hint: formRead.edit.hint,
      });
    },

    // эта функция вызывается после изменения статуса формы
    async onChangeFormState() {
      if (!this.formRead) {
        return;
      }

      this.formActions = await this.getFormActions(this.formRead);
    },

    onMergeFormData(formRead: Partial<FormReadDto>, tableName: string) {
      const infoData = this.getTableInfoData(formRead, tableName);
      const tableDataSet = this.tables[tableName].tableDataSet;
      if (!tableDataSet) {
        return;
      }

      tableDataSet.setInfoData(infoData);
    },

    async getFormRead() {
      const reportFormId = Number(this.$route.params.id);
      return (await ApiService.formRead(reportFormId)).json;
    },

    getTableInfoData(formRead: Partial<FormReadDto>, tableName: string) {
      return (
        Object.keys(FORM_INFO_KEYS) as Array<keyof typeof FORM_INFO_KEYS>
      ).reduce((infoData, key) => {
        const values = (formRead[FORM_INFO_KEYS[key]] || {})[tableName];
        if (values) {
          infoData[key] = values || [];
        }

        return infoData;
      }, {} as Dictionary<FormInfoDataDto[]>);
    },

    async init() {
      this.loading = true;
      this.formRead = null;
      this.formRepositoryRead = null;
      this.attrsDataSet = null;
      this.report = null;
      this.formActions = null;

      if (isNullOrUndefined(this.$route.params.id)) {
        return;
      }

      let formRepositoryRead: RepositoryReadDto;
      let formRead: FormReadDto;
      try {
        formRead = await this.getFormRead();
        formRepositoryRead = (
          await ApiService.repositoryRead<RepositoryReadDto>(formRead.form_no)
        ).json;
      } catch (error: any) {
        this.errorMessage = error?.message || error?.statusText || error;
        Logger.error({ error });
        return;
      }

      try {
        if (!isNullOrUndefined(formRead.report_id)) {
          this.report = (
            await ApiServiceRegReports.getReport(formRead.report_id)
          ).json;
        }

        this.attrsDataSet = new AttrsDataSet(
          "attrs-" + formRead.report_form_id,
          new AttrsDefaultRepository(),
          formRepositoryRead.form.form_main_attrs,
          formRead.form_main_data || {},
        );
        this.attrsDataSet.setEditByFormRead(formRead);
        for (const tableName of Object.keys(formRepositoryRead.form.tables)) {
          const tableDto = formRepositoryRead.form.tables[tableName];
          const {
            headers,
            model: modelDtoArray,
            enable_col_num,
            options: optionsDto,
          } = tableDto;

          const uniqId = toStr("report-form-id-" + formRead.report_form_id);
          const formNoTableUniqId = `${tableName}__${formRead.form_no}`;
          const filtersSaveModule = new PanelFiltersSaveModule(
            formNoTableUniqId,
          );
          await filtersSaveModule.init();
          const filtersSet = new PanelFiltersSet(filtersSaveModule);
          filtersSet.subject.subscribe((data) =>
            applyPanelFiltersData(
              this.tables[tableName].tableDataSet as TableDataSet,
              data,
            ),
          );
          const factory = new TableDataSetFactory({
            tableName,
            uniqId,
            headers,
            modelDtoArray,
            rows: formRead.form_table_data?.[tableName] ?? [],
            repository: new TableDefaultRepository(),
            types: formRepositoryRead.types,
            edit: {
              edit: formRead.edit.edit,
              comment: formRead.edit.comment,
              remark: formRead.edit.remark,
              warning: formRead.edit.warning,
              hint: formRead.edit.hint,
            },
            tableDataSetOptions: {
              infoData: this.getTableInfoData(formRead, tableName),
              form_id: formRead.report_form_id,
              helpHelper: this.helpHelper,
              display: {
                incCol: 1,
                incRow: 0,
                rowIndexCol: true,
                modelColNum: enable_col_num,
              },
              filtersSet,
              uniqueWordWrapSave: true,
              optionsDto,
            },
            enableAutoSaveSettings: true,
          });

          const tableDataSet = await factory.create();
          (
            tableDataSet.repository as TableDefaultRepository
          ).subjectExecuteResult.subscribe(({ changes, merge }) => {
            if ((!changes && !merge) || !this.formRead) {
              return;
            }

            if (merge) {
              this.onMergeFormData(merge, tableName);
            }

            if (changes) {
              const isChangeFormState =
                changes.form_state &&
                changes.form_state !== this.formRead.form_state;
              Object.assign(this.formRead, changes);
              this.updateReportingFormRules();
              if (isChangeFormState) {
                this.onChangeFormState();
              }
            }
          });
          this.tables[tableName] = {
            tableDataSet,
            factory,
            open: {
              info: true,
            },
            dto: tableDto,
          };
        }

        this.formRead = formRead;
        this.formRepositoryRead = formRepositoryRead;
        this.formActions = await this.getFormActions(formRead);

        this.repositoryExtensions =
          await AppApiRepositoryService.getExtensionsList().then(
            (x) => x.json.result,
          );
      } catch (e) {
        Logger.error(e);
        this.errorMessage = "Неизвестная ошибка";
      }

      this.loading = false;
    },
    async getFormActions(
      formRead: FormReadDto,
    ): Promise<ReportFormActionsDict> {
      try {
        return (await AppApiFormsService.getActions(formRead.report_form_id))
          .json;
      } catch (ex) {
        Logger.error(`getFormActions`, ex);
      }
      return {};
    },
  },
});
