
import { defineComponent, PropType } from "vue";
import {
  debounce,
  Dictionary,
  HelpHelper,
  isNumeric,
  ResizeComponent,
  TableDataSet,
} from "table";
import { currentSettings } from "@/services/UserSettingsService/helpers";
import TableInfoComponent from "@/modules/editor-forms/components/TableInfoComponent/TableInfoComponent.vue";
import {
  ActionButtonDto,
  ReportFormActionsDict,
} from "@/services/ApiActions/types";

export default defineComponent({
  name: "ReportingFormBottom",
  components: {
    ResizeComponent,
    TableInfoComponent,
  },
  props: {
    openInfo: Boolean,
    tableDataSet: { type: TableDataSet },
    helpHelper: {
      type: HelpHelper,
      required: true,
    },
    editTabButtons: {
      type: Object as PropType<Dictionary<Function>>,
    },
    formActions: {
      type: Object as PropType<ReportFormActionsDict>,
    },
  },
  emits: ["close", "click:form-action"],
  setup() {
    return {};
  },
  data() {
    return {
      height: undefined as number | undefined,
      saveSizeReportFormBottom: debounce((value: number | undefined) => {
        currentSettings.saveSizeReportFormBottom(value);
      }),
      rootRef: undefined as HTMLDivElement | undefined,
    };
  },
  computed: {
    divStyle() {
      return {
        height: this.getHeight(),
        minHeight: this.getHeight(),
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        position: "relative",
        boxSizing: "border-box",
        overflow: "auto",
      };
    },
  },
  watch: {},
  async created() {
    this.height = await currentSettings.getSizeReportFormBottom();
    if (!isNumeric(this.height)) {
      this.height = undefined;
    }
  },
  mounted() {
    this.updateRefs();
  },

  updated() {
    this.updateRefs();
  },
  methods: {
    updateRefs() {
      this.rootRef = this.$refs.root as HTMLDivElement;
    },

    getTableInfoWidth(): string {
      return this.helpHelper.help ? "calc(65vw - 50px)" : "100vw";
    },

    getHeight() {
      return this.height ? `${this.height}px` : "35%";
    },

    async onChangeSize(value: number | undefined) {
      this.height = value;
      await this.saveSizeReportFormBottom(value);
    },

    onClickFormAction(formAction: ActionButtonDto) {
      this.$emit("click:form-action", formAction);
    },
  },
});
