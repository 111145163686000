
import { defineComponent, PropType } from "vue";
import {
  ApiHelper,
  ApiService,
  ButtonComponent,
  FormReadDto,
  HttpService,
  KeyboardEventHelper,
  Logger,
  ModalComponent,
  NotificationsService,
  PanelFilters,
  PanelFiltersSet,
  RepositoryReadDto,
  RepositoryReadTableDto,
  ScreenSpinner,
  TableActionsComponent,
  TableCell,
  TableComponent,
  TableDataSet,
  TableDataSetFactory,
  TableLocalRepository,
  TableMultiHeaders,
  TInputComponent,
} from "table";
import {
  TableCellPointer,
  TableCellPointerServer,
} from "table/dist/components/TableComponent/common/types/TableCellPointer";
import { TableEmitUpdateCell } from "table/dist/components/TableComponent/common/types/TableEmitUpdateCell";
import { VocRepositoryDto } from "table/dist/services/Api/types/VocRepositoryDto";
import { PanelFiltersSaveModule } from "@/classes/PanelFiltersSaveModule";
import { applyPanelFiltersData } from "@/common/helpers/applyPanelFiltersData";
import { DEFAULT_LEFT_COLUMN_NAME } from "@/common/consts";

export interface FillByAnalyticalReportProps {
  pointer: Required<TableCellPointer>;
  tableDataSet: TableDataSet;
  repositoryReadDto: RepositoryReadDto;
  formReadDto: FormReadDto;
}

export default defineComponent({
  name: "FillByAnalyticalReportModal",
  components: {
    ScreenSpinner,
    PanelFilters,
    TableActionsComponent,
    ModalComponent,
    TableComponent,
    TableMultiHeaders,
    ButtonComponent,
    TInputComponent,
  },
  props: {
    propsObject: {
      type: Object as PropType<FillByAnalyticalReportProps>,
      required: true,
    },
  },
  emits: ["close"],
  setup() {
    return {
      DEFAULT_LEFT_COLUMN_NAME,
    };
  },
  data() {
    return {
      formData: {
        date: new Date().toISOString().split("T")[0],
      },
      table: {
        tableDataSet: null as TableDataSet | null,
        factory: null as TableDataSetFactory | null,
        loading: false,
      },
    };
  },
  computed: {
    readTableDto(): RepositoryReadTableDto {
      return this.propsObject.repositoryReadDto.form.tables[
        this.propsObject.tableDataSet.tableName
      ];
    },
  },
  watch: {},
  created() {},
  methods: {
    close() {
      this.$emit("close");
    },

    async onShapeReport() {
      this.table.loading = true;
      try {
        const result = await HttpService.get<{
          repository: VocRepositoryDto;
          form: Pick<FormReadDto, "form_table_data">;
        }>(
          "/api/vocs?" +
            ApiService.getQueryParams({
              action: "list_stock_list",
              date: this.formData.date,
              kod_org: this.propsObject.formReadDto.kod_org,
              form_no: this.propsObject.formReadDto.form_no,
            }),
        );

        const { form: formRead, repository: formRepositoryRead } = result.json;

        const tableName = "table" as keyof VocRepositoryDto["form"];
        const tableDto = formRepositoryRead.form.table;
        const { headers, model: modelDtoArray, options: optionsDto } = tableDto;
        const uniqId = `fill-analytical-report_${this.propsObject.formReadDto.form_no}`;
        const filtersSaveModule = new PanelFiltersSaveModule(uniqId);
        await filtersSaveModule.init();
        const filtersSet = new PanelFiltersSet(filtersSaveModule);
        filtersSet.subject.subscribe((data) =>
          applyPanelFiltersData(this.table.tableDataSet as TableDataSet, data),
        );
        this.table.factory = new TableDataSetFactory({
          headers,
          modelDtoArray,
          types: formRepositoryRead.types,
          repository: new TableLocalRepository(),
          tableName,
          uniqId,
          edit: {},
          rows: formRead.form_table_data?.[tableName] || [],
          tableDataSetOptions: {
            form_id: 0,
            // helpHelper: this.helpHelper,
            display: {
              incCol: 1,
              incRow: 0,
              rowIndexCol: true,
            },
            filtersSet,
            optionsDto,
          },
        });
        this.table.tableDataSet = await this.table.factory.create();
        this.table.tableDataSet.settingsService.setIsSaving(false);
      } catch (e: any) {
        NotificationsService.send({
          type: "error",
          title: "Ошибка при формировании отчёта",
          text: (await ApiHelper.getErrorMessage(e)) || "Неизвестная ошибка",
        });
        Logger.error({ e });
      }

      this.table.loading = false;
    },

    onConfirm() {
      const tablePointer = this.propsObject.pointer;
      const currentPointer = this.table.tableDataSet!.selected.cursor!;
      const tableDataSet = this.propsObject.tableDataSet;
      const selectedTableRow = this.table.tableDataSet?.getRowOrUndefined(
        currentPointer.row,
      );

      if (!selectedTableRow) {
        this.close();
        return;
      }

      const updates: TableEmitUpdateCell[] = [];
      Object.keys(selectedTableRow.cells).forEach((field) => {
        const cellData = selectedTableRow.cells[field];
        const pointer: TableCellPointerServer = {
          row: tablePointer.row,
          col_name: field,
        };
        const newValue = TableCell.getChangeValue(cellData, undefined);
        newValue.value = cellData.value;
        updates.push({
          pointer,
          newValue,
          original: selectedTableRow.original,
          changes: ["value"],
        });
      });
      tableDataSet.updateCells(updates);
      this.close();
    },

    onCellKeyDown({
      event,
      pointer,
    }: {
      event: KeyboardEvent;
      pointer: Required<TableCellPointer>;
    }) {
      const eventHelper = new KeyboardEventHelper(event);
      if (
        eventHelper.keyEnter.isAllTrue &&
        eventHelper.pressCtrl.pressShift.isAllFalse
      ) {
        this.onConfirm();
      }
    },
  },
});
