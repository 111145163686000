
import {
  defineComponent,
  PropType,
} from "vue";
import {
  boolToString,
  ButtonComponent,
  CircleLoader,
  FormReadDto,
  TABLE_ICONS,
} from "table";
import { ReportDto } from "@/modules/registration-reports/services/ApiServiceRegReports/types";
import ApiServiceRegReports from "@/modules/registration-reports/services/ApiServiceRegReports/ApiServiceRegReports";

export default defineComponent({
  name: "EditorFormsNavigation",
  components: {
    ButtonComponent,
    CircleLoader,
  },
  emits: ["close"],
  props: {
    visible: Boolean,
    report: {
      type: Object as PropType<ReportDto | undefined>,
      required: false,
    },
    currentReportForm: {
      type: Object as PropType<FormReadDto>,
      required: true,
    },
  },
  setup() {
    return {
      TABLE_ICONS,
      boolToString,
    };
  },
  data() {
    return {
      forms: null as FormReadDto[] | null,
      loading: true,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async loadCurrentReportForms() {
      if (!this.report) {
        return;
      }

      this.forms = (
        await ApiServiceRegReports.getFormListByFilter(this.report.report_id)
      ).json.result;
    },
    async init() {
      this.loading = true;
      this.forms = null;
      await this.loadCurrentReportForms();
      this.loading = false;
    },
    emitClose() {
      this.$emit("close");
    },
  },
  watch: {},
});
