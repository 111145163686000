
import { defineComponent, PropType } from "vue";
import {
  ButtonComponent,
  copy,
  Dictionary,
  FormReadDto,
  isNullOrUndefined,
  ModalComponent,
  PanelFilters,
  PanelFiltersSet,
  PointerHelper,
  RepositoryReadDto,
  RepositoryReadTableDto,
  ScreenSpinner,
  stringToFormInfoDataDto,
  TABLE_CONSTS,
  TableActionsComponent,
  TableComponent,
  TableDataSet,
  TableDataSetFactory,
  TableLocalRepository,
  TableMultiHeaders,
  toNumberOrDefault,
  toNumberOrUndefined,
} from "table";
import { TableCellPointerServer } from "table/dist/components/TableComponent/common/types/TableCellPointer";
import { TableEmitUpdateCell } from "table/dist/components/TableComponent/common/types/TableEmitUpdateCell";
import {
  tableRemarksHeaders,
  tableRemarksModelArray,
  tableRemarksTypes,
} from "@/modules/editor-forms/components/TableRemarksModal/consts";
import { PanelFiltersSaveModule } from "@/classes/PanelFiltersSaveModule";
import { applyPanelFiltersData } from "@/common/helpers/applyPanelFiltersData";
import { getFormInfoDataSortedComparer } from "@/modules/editor-forms/components/TableInfoComponent/helpers";

export interface TableRemarksProps {
  tableDataSet: TableDataSet;
  repositoryReadDto: RepositoryReadDto;
  formReadDto: FormReadDto;
}

const REMARKS_SPLITTER = "\n";

export default defineComponent({
  name: "TableRemarksModal",
  components: {
    TableActionsComponent,
    ModalComponent,
    TableComponent,
    TableMultiHeaders,
    ButtonComponent,
    ScreenSpinner,
    PanelFilters,
  },
  props: {
    propsObject: {
      type: Object as PropType<TableRemarksProps>,
      required: true,
    },
  },
  emits: ["close"],
  setup() {
    return {};
  },
  data() {
    return {
      table: null as null | {
        tableDataSet: TableDataSet;
        factory: TableDataSetFactory;
      },
      loading: false,
    };
  },
  computed: {
    readTableDto(): RepositoryReadTableDto {
      return this.propsObject.repositoryReadDto.form.tables[
        this.propsObject.tableDataSet.tableName
      ];
    },
  },
  watch: {},
  created() {
    this.initTable();
  },
  methods: {
    close() {
      this.$emit("close");
    },

    async initTable() {
      this.loading = true;
      const validationHandlers = copy(TABLE_CONSTS.VALIDATION_HANDLERS);
      validationHandlers.tableRemarksRow = (model, type, value) => {
        const rowDisplayNumber = toNumberOrUndefined(value);
        const row =
          rowDisplayNumber !== undefined
            ? this.propsObject.tableDataSet.getRowByDisplayRow(rowDisplayNumber)
            : undefined;
        if (
          !isNullOrUndefined(row) &&
          row < this.propsObject.tableDataSet.rows.length &&
          row >= 0
        ) {
          return {};
        }

        return {
          label: "Указанной строки не существует.",
        };
      };
      validationHandlers.tableRemarksCol = (model, type, value) => {
        const col_name = this.propsObject.tableDataSet.getColName(value);
        if (
          this.propsObject.tableDataSet.colIndexesUnref[col_name] !== undefined
        ) {
          return {};
        }

        return {
          label: "Указанной колонки не существует.",
        };
      };
      const rows: Dictionary[] = this.propsObject.tableDataSet.info
        .getFormInfoDataArray("remark")
        .filter((x) => x.pointer && x.value.text)
        .sort(
          getFormInfoDataSortedComparer(
            this.propsObject.tableDataSet.getColIndex.bind(
              this.propsObject.tableDataSet,
            ),
          ),
        )
        .flatMap((formInfoData) => {
          const pointer = formInfoData.pointer!;
          const row =
            typeof pointer === "object"
              ? typeof pointer.row === "number"
                ? this.propsObject.tableDataSet.getDisplayRowIndex(pointer.row)
                : pointer.row
              : "";
          const col =
            typeof pointer === "object" && "col_name" in pointer
              ? this.propsObject.tableDataSet.getDisplayColIndex(
                  pointer.col_name,
                )
              : "";

          const rowObj = {
            row,
            col,
            value: formInfoData.value.text,
          };

          if (rowObj.value.includes("\n")) {
            return rowObj.value
              .split("\n")
              .filter((x) => x.length > 0)
              .map((value: string) => ({
                ...rowObj,
                value,
              }));
          }

          return rowObj as Dictionary;
        });
      const uniqId = `table-remarks`;
      const filtersSaveModule = new PanelFiltersSaveModule(uniqId);
      await filtersSaveModule.init();
      const filtersSet = new PanelFiltersSet(filtersSaveModule);
      filtersSet.subject.subscribe((data) =>
        applyPanelFiltersData(this.table!.tableDataSet as TableDataSet, data),
      );
      const factory = new TableDataSetFactory({
        headers: tableRemarksHeaders,
        rows,
        edit: {
          edit: true,
        },
        tableDataSetOptions: {
          form_id: -1,
          display: {
            incCol: 1,
            incRow: 0,
            rowIndexCol: false,
          },
          virtualScrollMinLength: 0,
          validationHandlers,
          filtersSet,
        },
        types: tableRemarksTypes,
        uniqId,
        modelDtoArray: tableRemarksModelArray,
        tableName: "default",
        repository: new TableLocalRepository(),
        enableAutoSaveSettings: true,
      });
      const tableDataSet = await factory.create();
      this.table = {
        tableDataSet,
        factory,
      };
      this.loading = false;
    },

    async onConfirm() {
      const tableDataSet = this.propsObject.tableDataSet;
      await tableDataSet.waitChanges();
      const remarksDict: Dictionary<{
        value: string;
        pointer: TableCellPointerServer;
        original: any;
      }> = {};
      this.table!.tableDataSet.rows.forEach((tableRow, tableRowIndex) => {
        const value = tableRow.cells["value"].value;
        const rowRaw = tableRow.cells["row"].value;
        const rowValue = toNumberOrDefault(rowRaw, rowRaw);
        const row =
          typeof rowValue === "number"
            ? tableDataSet.getRowByDisplayRow(rowValue)
            : rowValue;
        const col_name = tableDataSet.getColName(tableRow.cells["col"].value);
        const pointer: TableCellPointerServer = {
          row, // тут может быть не число, игнорируем
          col_name,
        };
        const original = tableDataSet.getRowOrUndefined(pointer.row)?.original;
        const key = PointerHelper.getKey(pointer);
        if (remarksDict[key]?.value?.length) {
          remarksDict[key].value += REMARKS_SPLITTER + value;
          return;
        }

        remarksDict[key] = {
          value,
          pointer,
          original,
        };
      });
      const updates: TableEmitUpdateCell[] = [];

      Object.values(remarksDict).forEach(({ value, pointer, original }) => {
        updates.push({
          pointer,
          newValue: {
            value: undefined,
            info: {
              remark: stringToFormInfoDataDto(value, pointer),
            },
          },
          original,
          changes: ["remark"],
        });
      });

      const updatesPointersKeys = new Set<string>(
        updates.map((x) => PointerHelper.getKey(x.pointer)),
      );
      const removeRemarks: TableCellPointerServer[] =
        this.propsObject.tableDataSet.info
          .getFormInfoDataArray("remark")
          .map((x) => x.pointer as TableCellPointerServer)
          .filter(
            (pointer) =>
              "col_name" in pointer &&
              !updatesPointersKeys.has(PointerHelper.getKey(pointer)),
          );
      updates.push(
        ...removeRemarks.map((pointer) => {
          const original = tableDataSet.getRowOrUndefined(
            pointer.row,
          )?.original;
          return {
            pointer,
            newValue: {
              value: undefined,
              info: {},
            },
            original,
            changes: ["remark"],
          };
        }),
      );
      if (updates.length !== 0) {
        await tableDataSet.updateCells(updates);
      }

      this.close();
    },
  },
});
