import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "default-page-container" }
const _hoisted_2 = {
  class: "flex-column default-page-padding-inline",
  style: {"max-width":"200px"}
}
const _hoisted_3 = { class: "flex-column default-page-padding-inline" }
const _hoisted_4 = { class: "table-scroll-container" }
const _hoisted_5 = { class: "default-page-padding-inline padding-top-10 padding-bottom-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TInputComponent = _resolveComponent("TInputComponent")!
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!
  const _component_TableActionsComponent = _resolveComponent("TableActionsComponent")!
  const _component_PanelFilters = _resolveComponent("PanelFilters")!
  const _component_TableMultiHeaders = _resolveComponent("TableMultiHeaders")!
  const _component_TableComponent = _resolveComponent("TableComponent")!
  const _component_ScreenSpinner = _resolveComponent("ScreenSpinner")!
  const _component_ModalComponent = _resolveComponent("ModalComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ModalComponent, {
      "body-style": { height: '100%', padding: 0 },
      "content-style": { height: '100%' },
      style: { width: '95vw', height: '95vh' },
      visible: true,
      title: "Заполнить из аналитического отчёта",
      onClose: _ctx.close
    }, {
      body: _withCtx(({ close }) => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_TInputComponent, {
                modelValue: _ctx.formData.date,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.date) = $event)),
                caption: "Дата",
                type: "date",
                onReset: _cache[1] || (_cache[1] = ($event: any) => (_ctx.formData.date = ''))
              }, null, 8, ["modelValue"]),
              _createVNode(_component_ButtonComponent, {
                class: "form-button",
                style: {"margin-bottom":"10px","margin-top":"10px"},
                onClick: _ctx.onShapeReport
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Сформировать отчёт ")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            (_ctx.table.tableDataSet)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("div", _hoisted_3, [
                    (_ctx.table?.tableDataSet.isTableMounted)
                      ? (_openBlock(), _createBlock(_Teleport, {
                          key: 0,
                          disabled: !_ctx.table.tableDataSet.isFullScreenAndMounted,
                          to: '#' + _ctx.table.tableDataSet.idContainerDivUp
                        }, [
                          _createVNode(_component_TableActionsComponent, {
                            "table-data-set": _ctx.table.tableDataSet,
                            "changes-state": "hide",
                            class: "padding-bottom-10",
                            "filter-state": "enable",
                            "row-append-state": "hide"
                          }, null, 8, ["table-data-set"]),
                          (
                    _ctx.table.tableDataSet &&
                    _ctx.table.tableDataSet.options.filtersSet.open
                  )
                            ? (_openBlock(), _createBlock(_component_PanelFilters, {
                                key: 0,
                                "data-set": _ctx.table.tableDataSet.options.filtersSet,
                                "model-array": _ctx.table.tableDataSet.modelUnref,
                                "table-data-set": _ctx.table.tableDataSet,
                                types: _ctx.table.tableDataSet.types,
                                class: "padding-bottom-10"
                              }, null, 8, ["data-set", "model-array", "table-data-set", "types"]))
                            : _createCommentVNode("", true)
                        ], 8, ["disabled", "to"]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_TableComponent, {
                      isShowInfo: false,
                      isViewColumnNumber: true,
                      "table-data-set": _ctx.table.tableDataSet,
                      "onUpdate:cells": _cache[2] || (_cache[2] = ($event: any) => (_ctx.table.tableDataSet.updateCells($event))),
                      onCellKeydown: _ctx.onCellKeyDown
                    }, {
                      headers: _withCtx(({ classes, tableDataSet }) => [
                        _createVNode(_component_TableMultiHeaders, {
                          classes: classes,
                          "left-col": _ctx.DEFAULT_LEFT_COLUMN_NAME,
                          "table-data-set": tableDataSet,
                          onClickLeftCol: ($event: any) => (tableDataSet.selectedAll(this.$nextTick))
                        }, null, 8, ["classes", "left-col", "table-data-set", "onClickLeftCol"])
                      ]),
                      _: 1
                    }, 8, ["table-data-set", "onCellKeydown"])
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_ButtonComponent, {
                      disabled: !_ctx.table.tableDataSet.selected.cursor,
                      class: "form-button",
                      onClick: _ctx.onConfirm
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Подтвердить ввод ")
                      ]),
                      _: 1
                    }, 8, ["disabled", "onClick"])
                  ])
                ], 64))
              : (_ctx.table.loading)
                ? (_openBlock(), _createBlock(_component_ScreenSpinner, {
                    key: 1,
                    dimming: false
                  }))
                : _createCommentVNode("", true)
          ])
        ])
      ]),
      _: 1
    }, 8, ["onClose"]),
    _createTextVNode(" > ")
  ], 64))
}