import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"height":"100%"} }
const _hoisted_2 = { style: {"display":"flex","flex-direction":"column","height":"100%"} }
const _hoisted_3 = { style: {"display":"flex","gap":"10px","flex-wrap":"wrap","justify-content":"space-between","margin-bottom":"10px"} }
const _hoisted_4 = { class: "table-scroll-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableActionsComponent = _resolveComponent("TableActionsComponent")!
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!
  const _component_PanelFilters = _resolveComponent("PanelFilters")!
  const _component_TableMultiHeaders = _resolveComponent("TableMultiHeaders")!
  const _component_TableComponent = _resolveComponent("TableComponent")!
  const _component_ScreenSpinner = _resolveComponent("ScreenSpinner")!
  const _component_ModalComponent = _resolveComponent("ModalComponent")!

  return (_openBlock(), _createBlock(_component_ModalComponent, {
    "body-style": { height: '100%' },
    "content-style": { height: '100%' },
    style: { width: '95vw', height: '95vh' },
    visible: true,
    title: "Редактирование примечаний",
    onClose: _ctx.close
  }, {
    body: _withCtx(({ close }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.table)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.table.tableDataSet.isTableMounted)
                  ? (_openBlock(), _createBlock(_Teleport, {
                      key: 0,
                      disabled: !_ctx.table.tableDataSet.isFullScreenAndMounted,
                      to: '#' + _ctx.table.tableDataSet.idContainerDivUp
                    }, [
                      _createElementVNode("div", _hoisted_3, [
                        _createVNode(_component_TableActionsComponent, {
                          "table-data-set": _ctx.table.tableDataSet,
                          "filter-state": "enable",
                          "update-indexes-state": "hide"
                        }, null, 8, ["table-data-set"]),
                        _createVNode(_component_ButtonComponent, {
                          disabled: !_ctx.table.tableDataSet.selected.cursor,
                          class: "form-button",
                          onClick: _ctx.onConfirm
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(" Сохранить ")
                          ]),
                          _: 1
                        }, 8, ["disabled", "onClick"])
                      ]),
                      (_ctx.table?.tableDataSet.options.filtersSet.open)
                        ? (_openBlock(), _createBlock(_component_PanelFilters, {
                            key: 0,
                            "data-set": _ctx.table.tableDataSet.options.filtersSet,
                            "model-array": _ctx.table.tableDataSet.modelUnref,
                            "table-data-set": _ctx.table.tableDataSet,
                            types: _ctx.table.tableDataSet.types,
                            style: {"margin-bottom":"10px"}
                          }, null, 8, ["data-set", "model-array", "table-data-set", "types"]))
                        : _createCommentVNode("", true)
                    ], 8, ["disabled", "to"]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_TableComponent, {
                    isShowInfo: false,
                    isViewColumnNumber: false,
                    "table-data-set": _ctx.table.tableDataSet,
                    "onUpdate:cells": _cache[0] || (_cache[0] = ($event: any) => (_ctx.table.tableDataSet.updateCells($event))),
                    onRowAppend: _cache[1] || (_cache[1] = ($event: any) => (_ctx.table.tableDataSet.rowAppend())),
                    onRowInsert: _cache[2] || (_cache[2] = ($event: any) => (_ctx.table.tableDataSet.rowInsert($event))),
                    onRowDelete: _cache[3] || (_cache[3] = ($event: any) => (_ctx.table.tableDataSet.rowDelete($event))),
                    onRowDuplicate: _cache[4] || (_cache[4] = ($event: any) => (_ctx.table.tableDataSet.rowDuplicate($event))),
                    onRowDown: _cache[5] || (_cache[5] = ($event: any) => (_ctx.table.tableDataSet.rowMoveDown($event))),
                    onRowUp: _cache[6] || (_cache[6] = ($event: any) => (_ctx.table.tableDataSet.rowMoveUp($event)))
                  }, {
                    headers: _withCtx(({ classes, tableDataSet }) => [
                      _createVNode(_component_TableMultiHeaders, {
                        classes: classes,
                        "table-data-set": tableDataSet,
                        onClickLeftCol: ($event: any) => (tableDataSet.selectedAll(this.$nextTick))
                      }, null, 8, ["classes", "table-data-set", "onClickLeftCol"])
                    ]),
                    _: 1
                  }, 8, ["table-data-set"])
                ])
              ], 64))
            : (_ctx.loading)
              ? (_openBlock(), _createBlock(_component_ScreenSpinner, {
                  key: 1,
                  dimming: false
                }))
              : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["onClose"]))
}