import {
  Dictionary,
  HeaderType,
  RepositoryReadModelDto,
  RepositoryReadTypeDto,
} from "table";

export const tableRemarksHeaders: HeaderType[] = ["row", "col", "value"];

export const tableRemarksModelArray: RepositoryReadModelDto[] = [
  {
    field: "row",
    caption: "№ строки",
    type: "row",
  },
  {
    field: "col",
    caption: "№ графы",
    type: "col",
  },
  {
    field: "value",
    caption: "Примечание",
    width: 500,
  },
];

export const tableRemarksTypes: Dictionary<RepositoryReadTypeDto> = {
  row: {
    base_type: "BASE",
    validation: {
      type: "int",
      handlers: ["tableRemarksRow"],
    },
  },
  col: {
    base_type: "BASE",
    validation: {
      type: "str",
      handlers: ["tableRemarksCol"],
    },
  },
};
