import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "info-only-header__content-col"
}
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isViewRow)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.rowCaption), 1))
      : _createCommentVNode("", true),
    (_ctx.isViewColName)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.colCaption), 1))
      : _createCommentVNode("", true),
    (!_ctx.isViewRow && !_ctx.isViewColName)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3))
      : _createCommentVNode("", true)
  ], 64))
}