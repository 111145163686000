import {
  ApiHelper,
  ApiService,
  Dictionary,
  FormReadDto,
  NotificationsService,
} from "table";
import { AttrsDataSet } from "@/modules/editor-forms/components/ReportFormAttrs/classes/AttrsDataSet";

export class AttrsDefaultRepository {
  loading: Dictionary<number> = {};

  addLoading(field: string) {
    if (this.loading[field] === undefined) {
      this.loading[field] = 0;
    }

    this.loading[field]++;
  }

  finishLoading(field: string) {
    this.loading[field]--;
  }

  async save(
    formRead: FormReadDto,
    attrs: Dictionary,
    attrsDataSet: AttrsDataSet,
  ) {
    Object.keys(attrs).forEach((key) => this.addLoading(key));
    try {
      const result = await ApiService.postForm(
        "change_attrs",
        formRead.report_form_id,
        "",
        {
          attrs: Object.keys(attrs).map((key) => ({
            attr: key,
            value: attrs[key],
          })),
        },
      );
      Object.keys(attrs).forEach((key) => {
        this.finishLoading(key);
        if (!formRead.form_main_data) {
          formRead.form_main_data = {};
        }

        formRead.form_main_data[key] = attrs[key];
      });
      return { result: true };
    } catch (e: any) {
      const error = await ApiHelper.getError(e);
      if (error.type === "PermissionDeniedException") {
        NotificationsService.send({
          type: "error",
          title: `Ошибка при сохранении изменений в форме. Дальнейшее редактирование запрещено.`,
          text: error.getMessage(),
        });
        attrsDataSet.setEdit();
      }

      return {
        result: false,
        cancelChanges: true,
      };
    }
  }
}
