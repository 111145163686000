
import {
  defineComponent,
  PropType,
} from "vue";
import {
  Dictionary,
  FormInfoDataDto,
  isNullOrUndefined,
  isNumeric,
  TableDataSet,
} from "table";

const dictReplaced: Dictionary = {
  position: "Должность исполнителя",
  full_name: "ФИО исполнителя",
  phone: "Телефон исполнителя",
  email: "Почта исполнителя",
};

export default defineComponent({
  name: "TableInfoPointerView",
  components: {},
  props: {
    value: {
      type: Object as PropType<FormInfoDataDto>,
      required: true,
    },
    tableDataSet: {
      type: Object as PropType<TableDataSet>,
      required: true,
    },
  },
  emits: [],
  setup() {
    return {};
  },
  data() {
    return {};
  },
  computed: {
    isViewColName() {
      return (
        typeof this.value.pointer === "object" &&
        "col_name" in this.value.pointer &&
        this.value.pointer.col_name.length > 0
      );
    },
    isViewRow() {
      return (
        typeof this.value.pointer === "object" &&
        !isNullOrUndefined(this.value.pointer.row)
      );
    },

    rowCaption() {
      const { pointer } = this.value;
      if (typeof pointer !== "object") {
        return "";
      }

      if (!isNumeric(pointer.row)) {
        return pointer.row;
      }

      return `Строка ${this.tableDataSet.getDisplayRowIndex(pointer.row)}`;
    },

    colCaption() {
      const { pointer } = this.value;
      if (typeof pointer !== "object") {
        return "";
      }

      if (!("col_name" in pointer)) {
        return "";
      }

      if (pointer.col_name in dictReplaced) {
        return dictReplaced[pointer.col_name];
      }

      return this.tableDataSet.getColCaption(pointer.col_name);
    },
  },
  watch: {},
  created() {
  },
  methods: {},
});
