
import { defineComponent } from "vue";
import { currentSettings } from "@/services/UserSettingsService/helpers";
import { SwitchComponent } from "table";

export default defineComponent({
  name: "SwitchHints",
  components: { SwitchComponent },
  props: {
    modelValue: Boolean,
  },
  emits: ["update:modelValue"],
  setup() {
    return {};
  },
  data() {
    return {
      savedValue: true,
    };
  },
  computed: {},
  watch: {},
  async created() {
    this.$watch(
      () => this.modelValue,
      () => this.saveModelValue(),
    );
    this.savedValue = await currentSettings.getHints();
    this.updateModelValue(this.savedValue);
  },
  methods: {
    async saveModelValue() {
      if (this.modelValue !== this.savedValue) {
        await currentSettings.saveHints(this.modelValue);
      }
    },
    updateModelValue(newValue: boolean) {
      if (this.modelValue !== newValue) {
        this.$emit("update:modelValue", newValue);
      }
    },
  },
});
