
import { defineComponent, PropType } from "vue";
import {
  AttrsComponent,
  copy,
  debounce,
  Dictionary,
  FormReadDto,
  RepositoryReadDto,
  RepositoryReadModelDto,
  toStr,
} from "table";
import { ApiVocUserDto } from "@/services/AppUsersService/types";
import { UserHelper } from "@/common/helpers/UserHelper";
import CreateVocEntryButton from "@/components/smart/CreateVocEntryButton.vue";
import { GLOBAL_ATTRS_HANDLERS } from "@/common/consts";

interface SystemFieldUpdate {
  field: string;
  value: any;

  cancelChanges(): any;
}

export default defineComponent({
  name: "SystemAttrsComponent",
  props: {
    formRead: {
      type: Object as PropType<FormReadDto>,
      required: true,
    },
    formRepositoryRead: {
      type: Object as PropType<RepositoryReadDto>,
      required: true,
    },
    changeAttrsDebounce: { type: Number },
    usersDict: {
      type: Object as PropType<Dictionary<ApiVocUserDto>>,
      required: true,
    },
    style: {},
    edit: Boolean,
  },
  emits: ["update:fields"],
  components: {
    CreateVocEntryButton,
    AttrsComponent,
  },
  setup() {
    return {
      UserHelper,
      String,
      GLOBAL_ATTRS_HANDLERS,
    };
  },
  data() {
    return {
      formReadData: {} as Partial<FormReadDto>,
      fieldsUpdates: {} as Dictionary<SystemFieldUpdate>,
      loading: {} as Dictionary<number>,
      formReadDataInit: {} as Partial<FormReadDto>,
    };
  },
  created() {
    if (this.changeAttrsDebounce) {
      this.onEmitCurrentUpdatesFields = debounce(
        this.onEmitCurrentUpdatesFields,
        this.changeAttrsDebounce,
      );
    }

    this.initFormReadData();
  },
  methods: {
    initFormReadData() {
      this.formRepositoryRead.form.system_attrs
        ?.map((x) => x.field)
        .forEach((field) => {
          (this.formReadData as any)[field] = toStr(
            (this.formRead as any)[field],
          );
        });
      this.formReadDataInit = copy(this.formReadData);
    },

    onUpdateField(field: string, value: any) {
      const prevValue = (this.formReadData as any)[field];
      if (prevValue === value) {
        return;
      }

      const cancelChanges = () => {
        (this.formReadData as any)[field] = prevValue;
      };
      (this.formReadData as any)[field] = value;
      this.addUpdateFields([
        {
          field,
          value,
          cancelChanges,
        },
      ]);
    },

    onReset(model: RepositoryReadModelDto) {
      this.onUpdateField(
        model.field,
        (this.formReadDataInit as any)[model.field],
      );
    },

    onChangeField({
      model,
      value,
    }: {
      model: RepositoryReadModelDto;
      value: any;
    }) {
      this.onUpdateField(model.field, value);
    },

    addUpdateFields(updates: SystemFieldUpdate[]) {
      updates.forEach((update) => {
        this.loading[update.field] ||= 0;
        this.loading[update.field]++;
      });
      updates.forEach((update) => {
        this.fieldsUpdates[update.field] = update;
      });
      this.onEmitCurrentUpdatesFields();
    },

    onEmitCurrentUpdatesFields() {
      const fieldsUpdates = this.fieldsUpdates;
      this.$emit(
        "update:fields",
        Object.keys(fieldsUpdates).map((field) => fieldsUpdates[field]),
      );
      this.fieldsUpdates = {};
      Object.keys(fieldsUpdates).forEach((field) => {
        this.loading[field]--;
      });
    },
  },
});
