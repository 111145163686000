import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { style: {"height":"100%"} }
const _hoisted_2 = { style: {"display":"flex","justify-content":"flex-end"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AttrsComponent = _resolveComponent("AttrsComponent")!
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!
  const _component_ModalComponent = _resolveComponent("ModalComponent")!

  return (_openBlock(), _createBlock(_component_ModalComponent, {
    "body-style": { height: '100%', overflow: 'auto' },
    "content-style": { height: '100%' },
    style: { width: '95vw', maxHeight: '95vh' },
    title: _ctx.propsObject.repExt.caption,
    visible: true,
    onClose: _ctx.close
  }, {
    body: _withCtx(({ close }) => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.propsObject.repExt.params)
          ? (_openBlock(), _createBlock(_component_AttrsComponent, {
              key: 0,
              data: _ctx.currentData,
              "global-handlers": _ctx.GLOBAL_ATTRS_HANDLERS,
              "models-dto": _ctx.propsObject.repExt.params,
              readOnly: false,
              types: _ctx.propsObject.tableDataSet.types,
              "unique-id": 'extension-' + _ctx.propsObject.tableDataSet.uniqueId,
              "class-attrs-container": "editing-voc-standard-demo-grid",
              "default-input-container-style": "width: 300px",
              onReset: _ctx.onResetField,
              onChangeField: _ctx.onChangeField
            }, null, 8, ["data", "global-handlers", "models-dto", "types", "unique-id", "onReset", "onChangeField"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ButtonComponent, {
            class: "form-button",
            "data-type": "accent",
            onClick: _ctx.onClickExecute
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Подтвердить ")
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["title", "onClose"]))
}