
import "../../../../styles/tabs.scss";
import {
  boolToString,
  ButtonComponent,
  dateFormat,
  Dictionary,
  FormInfoDataDto,
  Logger,
  PointerHelper,
  TABLE_ICONS,
  TableDataSet,
  TableInfo,
} from "table";
import { defineComponent, PropType } from "vue";
import { TableCellPointerServer } from "table/dist/components/TableComponent/common/types/TableCellPointer";
import { FORM_INFO_KEYS } from "@/modules/editor-forms/common/consts";
import { ActionButtonDto } from "@/services/ApiActions/types";
import ActionsButtons from "@/components/ActionButton/ActionsButtons.vue";
import TableInfoTabButtons from "@/modules/editor-forms/components/TableInfoComponent/TableInfoTabButtons.vue";
import { getFormInfoDataSortedComparer } from "@/modules/editor-forms/components/TableInfoComponent/helpers";
import TableInfoPointerView from "@/modules/editor-forms/components/TableInfoComponent/TableInfoPointerView.vue";

export default defineComponent({
  name: "TableInfoComponent",
  components: {
    TableInfoPointerView,
    TableInfoTabButtons,
    ActionsButtons,
    ButtonComponent,
  },
  emits: [
    "close", // нажатие крестика
    "click:form-action",
  ],
  props: {
    tableDataSet: {
      type: Object as PropType<TableDataSet>,
      required: true,
    },
    editTabButtons: {
      type: Object as PropType<Dictionary<Function>>,
    },
    actions: {
      type: Object as PropType<Dictionary<ActionButtonDto[]>>,
      required: false,
    },
  },
  setup() {
    return {
      boolToString,
      dateFormat,
      FORM_INFO_KEYS,
    };
  },
  data() {
    return {
      TABLE_ICONS,
    };
  },
  computed: {
    tab() {
      return this.tableDataSet.info.tab;
    },

    formInfoDataSortedComparer() {
      return getFormInfoDataSortedComparer(
        this.tableDataSet.getColIndex.bind(this.tableDataSet),
      );
    },

    remarksSorted(): (FormInfoDataDto | undefined)[] {
      return Object.values(
        this.tableDataSet.info.dataSet.getByDataKey<FormInfoDataDto>("remark"),
      ).sort(this.formInfoDataSortedComparer);
    },

    commentsSorted(): (FormInfoDataDto | undefined)[] {
      return Object.values(
        this.tableDataSet.info.dataSet.getByDataKey<FormInfoDataDto>("comment"),
      ).sort(this.formInfoDataSortedComparer);
    },
    errorsSorted(): (FormInfoDataDto | undefined)[] {
      return Object.values(
        this.tableDataSet.info.dataSet.getByDataKey<FormInfoDataDto>("error"),
      ).sort(this.formInfoDataSortedComparer);
    },
    notificationsSorted(): (FormInfoDataDto | undefined)[] {
      return Object.values(
        this.tableDataSet.info.dataSet.getByDataKey<FormInfoDataDto>(
          "notification",
        ),
      );
    },
  },
  methods: {
    remove(value: FormInfoDataDto, mode: string) {
      if (!value.pointer || typeof value.pointer !== "object") {
        return;
      }

      if (PointerHelper.isRowPointer(value.pointer)) {
        this.tableDataSet.updateRows([
          this.tableDataSet.getEmitUpdateRow({
            pointer: value.pointer,
            newValue: { [mode]: undefined },
            changes: [mode],
          }),
        ]);
        return;
      }

      this.tableDataSet.updateCells([
        this.tableDataSet.getEmitUpdateCell({
          pointer: value.pointer,
          newValue: {
            value: undefined,
            info: { [mode]: undefined },
          },
          changes: [mode],
        }),
      ]);
    },

    setTab(tab: TableInfo["tab"]) {
      this.tableDataSet.info.setTab(tab);
    },

    onClickInfoData(infoDataDto: FormInfoDataDto) {
      if (!infoDataDto.pointer) {
        return;
      }

      this.tableDataSet.selected.disableSelectedMode();
      try {
        let pointer: Required<TableCellPointerServer>;
        if (typeof infoDataDto.pointer !== "object") {
          return;
        } else if (PointerHelper.isRowPointer(infoDataDto.pointer)) {
          pointer = PointerHelper.getRequired(this.tableDataSet, {
            row: infoDataDto.pointer.row,
            col: this.tableDataSet.getFirstColVisible(),
          });
        } else {
          pointer = PointerHelper.getRequired(
            this.tableDataSet,
            infoDataDto.pointer,
          );
        }

        this.tableDataSet.setCursor(pointer);
      } catch (e) {
        Logger.log("Возможно ячейка не существует.", { e });
      }
    },

    onClickFormAction(formAction: ActionButtonDto) {
      this.$emit("click:form-action", formAction);
    },
  },
});
