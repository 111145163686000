import { cloneDeep } from "table";
import { ReportFilter } from "@/modules/registration-reports/common/types";

const initFilterData = {} as ReportFilter;

const getInitFilterData = () => cloneDeep(initFilterData);

export const reportHelpers = {
  getInitFilterData,
};
