import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResizeComponent = _resolveComponent("ResizeComponent")!
  const _component_TableInfoComponent = _resolveComponent("TableInfoComponent")!

  return (_ctx.openInfo && _ctx.tableDataSet)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        ref: "root",
        style: _normalizeStyle(_ctx.divStyle)
      }, [
        (_ctx.rootRef)
          ? (_openBlock(), _createBlock(_component_ResizeComponent, {
              key: 0,
              "element-ref": _ctx.rootRef,
              size: _ctx.height,
              style: { marginTop: '8px' },
              onChange: _ctx.onChangeSize,
              onDblclick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onChangeSize(undefined)))
            }, null, 8, ["element-ref", "size", "onChange"]))
          : _createCommentVNode("", true),
        _createVNode(_component_TableInfoComponent, {
          actions: _ctx.formActions?.bottom_tabs,
          "edit-tab-buttons": _ctx.editTabButtons,
          style: _normalizeStyle({ width: _ctx.getTableInfoWidth() }),
          "table-data-set": _ctx.tableDataSet,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close'))),
          "onClick:formAction": _ctx.onClickFormAction
        }, null, 8, ["actions", "edit-tab-buttons", "style", "table-data-set", "onClick:formAction"])
      ], 4))
    : _createCommentVNode("", true)
}