
import {
  defineComponent,
  PropType,
} from "vue";
import { TableDataSet } from "table";

export default defineComponent({
  name: "HeaderSaveIndicator",
  components: {},
  props: {
    tableDataSet: {
      type: Object as PropType<TableDataSet>,
      required: false,
    },
  },
  emits: [],
  setup() {
    return {};
  },
  data() {
    return {};
  },
  computed: {
    divStyle() {
      const isQueueAny = Boolean(this.tableDataSet?.repository.queue.length);
      return {
        color: isQueueAny ? "darkred" : "#75818f",
        opacity: isQueueAny ? 1 : 0,
      };
    },
  },
  watch: {},
  created() {
  },
  methods: {},
});
