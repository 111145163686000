import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ab6c334"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav-footer-modal editor-forms-nav" }
const _hoisted_2 = { style: {"display":"flex","justify-content":"right","margin":"14px"} }
const _hoisted_3 = { class: "editor-forms-nav-title" }
const _hoisted_4 = {
  key: 0,
  class: "editor-forms-nav-list editor-forms-nav-list-value"
}
const _hoisted_5 = {
  key: 1,
  class: "editor-forms-nav-list"
}
const _hoisted_6 = ["data-active", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!
  const _component_CircleLoader = _resolveComponent("CircleLoader")!

  return (_ctx.visible)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        _createElementVNode("div", {
          class: "nav-footer-modal-overlay",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.emitClose && _ctx.emitClose(...args)))
        }),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_ButtonComponent, {
              icon: _ctx.TABLE_ICONS.close,
              class: "button-opacity",
              onClick: _ctx.emitClose
            }, null, 8, ["icon", "onClick"])
          ]),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.currentReportForm.form_name), 1),
          (_ctx.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_CircleLoader)
              ]))
            : _createCommentVNode("", true),
          (_ctx.forms)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.forms, (form) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: form.report_form_id,
                    "data-active": 
            _ctx.boolToString(
              this.currentReportForm.report_form_id === form.report_form_id,
            )
          ,
                    class: "editor-forms-nav-list-value",
                    onClick: ($event: any) => (_ctx.$router.push('/editor-forms/' + form.report_form_id))
                  }, _toDisplayString(form.form_name), 9, _hoisted_6))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ])
      ], 64))
    : _createCommentVNode("", true)
}