import { resolveComponent as _resolveComponent, createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "default-page-container" }
const _hoisted_2 = {
  key: 1,
  class: "default-page-container default-page-padding-inline",
  style: {"font-size":"30px","color":"#a80000"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SwitchEdit = _resolveComponent("SwitchEdit")!
  const _component_SwitchHints = _resolveComponent("SwitchHints")!
  const _component_ReportingForm = _resolveComponent("ReportingForm")!
  const _component_ScreenSpinner = _resolveComponent("ScreenSpinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_Teleport, {
      to: '#' + _ctx.HEADER_IDS.right
    }, [
      _createVNode(_component_SwitchEdit, {
        "model-value": _ctx.edit,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.edit = $event))
      }, null, 8, ["model-value"]),
      _createVNode(_component_SwitchHints, {
        "model-value": _ctx.helpHelper.visible,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.helpHelper.setVisible($event)))
      }, null, 8, ["model-value"])
    ], 8, ["to"])),
    _createElementVNode("div", _hoisted_1, [
      (
        !_ctx.loading &&
        _ctx.formRead &&
        _ctx.formRepositoryRead &&
        _ctx.repositoryExtensions !== null &&
        _ctx.formActions !== null
      )
        ? (_openBlock(), _createBlock(_component_ReportingForm, {
            key: 0,
            attrsDataSet: _ctx.attrsDataSet,
            edit: _ctx.edit,
            "form-actions": _ctx.formActions,
            "form-read": _ctx.formRead,
            "form-repository-read": _ctx.formRepositoryRead,
            "help-helper": _ctx.helpHelper,
            report: _ctx.report,
            "repository-extensions": _ctx.repositoryExtensions,
            tables: _ctx.tables,
            onReloadForm: _ctx.init,
            onUpdateFormRead: _cache[2] || (_cache[2] = ($event: any) => (_ctx.formRead = $event)),
            onReloadFormRead: _ctx.reloadFormRead
          }, null, 8, ["attrsDataSet", "edit", "form-actions", "form-read", "form-repository-read", "help-helper", "report", "repository-extensions", "tables", "onReloadForm", "onReloadFormRead"]))
        : (_ctx.errorMessageLabel.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.errorMessageLabel), 1))
          : (_openBlock(), _createBlock(_component_ScreenSpinner, {
              key: 2,
              dimming: false
            }))
    ])
  ], 64))
}