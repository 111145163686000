import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DropdownComponent = _resolveComponent("DropdownComponent")!
  const _component_DynamicComponents = _resolveComponent("DynamicComponents")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.type === 'dropdown')
      ? (_openBlock(), _createBlock(_component_DropdownComponent, {
          key: 0,
          containerWidth: '300px',
          options: _ctx.calcDropdownOptions,
          classButton: "button-blue"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Калькуляторы ")
          ]),
          _: 1
        }, 8, ["options"]))
      : _createCommentVNode("", true),
    (_ctx.type === 'list')
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.CALC_COMPONENTS, (value) => {
          return (_openBlock(), _createElementBlock("button", {
            key: value.component.name,
            class: "button-opacity app-menu-button",
            onClick: ($event: any) => (_ctx.openCalc(value.component.name))
          }, _toDisplayString(value.title), 9, _hoisted_1))
        }), 128))
      : _createCommentVNode("", true),
    _createVNode(_component_DynamicComponents, {
      components: _ctx.show.calcNameDict,
      "onClose:component": _ctx.hideCalc,
      "onOpen:component": _ctx.openCalc
    }, null, 8, ["components", "onClose:component", "onOpen:component"])
  ], 64))
}